#staggered-slide-show {
    > .wrapper {
        .dowm {
            margin-top: -1rem;
        }

        .flex-2 {
            flex: 2;
        }

        .img-item {
            padding-bottom: calc(100% / 16 * 9);
            width: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .flex-1 {
            .img-item {
                padding-bottom: calc(100% / 8 * 9 + 1rem);
                width: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
        }
    }
}
