@import "../../based";

$item-border: 1px dotted rgba($white, 0.3);
$light-item-border: 1px dotted rgba($blue, 0.3);

#menu {
    .menu-container {
        width: 100%;
        height: 100%;
        background-color: $dark-blue;

        .menu-item {
            @extend %button;

            position: relative;
            padding: 1rem 2rem;
            font-size: 1.2rem;
            font-weight: bolder;
            white-space: nowrap;
            color: $white;
            border-bottom: $item-border;
            text-transform: uppercase;

            .hover-bg {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba($white, 0);
                transition: 0.5s;
            }

            &:hover,
            &.active {
                .hover-bg {
                    background-color: rgba($white, 0.1);
                }
            }

            &.active {
                cursor: default;
                pointer-events: none;
            }
        }
    }

    // &.dark {
    //     .menu-container {
    //         background-color: $dark-blue;

    //         .menu-item {
    //             color: $white;
    //             border-bottom: $item-border;

    //             .hover-bg {
    //                 background-color: rgba($white, 0);
    //             }

    //             &:hover {
    //                 .hover-bg {
    //                     background-color: rgba($white, 0.1);
    //                 }
    //             }
    //         }
    //     }
    // }

    &.light {
        .menu-container {
            background-color: $white;

            .menu-item {
                color: $logo-blue;
                border-bottom: $light-item-border;

                .hover-bg {
                    background-color: rgba($blue, 0);
                }

                &:hover,
                &.active {
                    .hover-bg {
                        background-color: rgba($blue, 0.1);
                    }
                }

                &.active {
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }

    &.mobile {
        .menu-container {
            .menu-item {
                @extend %button;

                font-size: 1rem;
                white-space: wrap;
            }
        }
    }
}
