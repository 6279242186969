@import "../../../based";

#dotted-divider {
    display: flex;
    align-items: center;

    .line {
        flex: 1;
        height: 1px;
        border-top: 1px dashed $white;
    }

    .sailboat {
        margin: 0 1rem;
        width: 1.4rem;
        height: 1.4rem;
        background-image: url("../../../assets/images/icon/sailboat.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}
