$grey: #707070;
$light-blue: #90d4f8;
$blue: #3382d8;
$logo-blue: #235999;
$dark-blue: #002a62;
$darker-blue: #001b40;
$white: #fff;
$content: $white;
$header: 67px;
$background-z: 0;
$page: 10;
$page-pop: 20;
$popup-z: 50;
$header-z: 60;
$cover-z: 70;
$screen-sm-min: 992px;
$screen-md-min: 1280px;
$screen-lg-min: 1440px;
$screen-xl-min: 1920px;
