@import "../../../based";

#bg-img-display {
    padding: 0.5rem;
    background-color: rgba($blue, 0.3);

    .img-container {
        width: 100%;
        background-position: center center;
        background-size: cover;
        background-color: #000;

        &.rect {
            padding-bottom: 100%;
        }

        &.s4-3 {
            padding-bottom: calc(100% / 4 * 3);
        }

        &.s16-9 {
            padding-bottom: calc(100% / 16 * 9);
        }
    }

    .desc {
        font-size: 1rem;
        text-align: center;
        white-space: pre-line;
        line-height: 1rem;
        color: $white;
    }
}
