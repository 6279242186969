@import "../../../based";

#simple-img-display {
    .img-container {
        padding-bottom: calc(100% / 300 * 220);
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
    }

    .desc {
        text-align: center;
        color: $grey;
    }
}
