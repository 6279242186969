@import "../../based";

$banner: 220px;
$deco-bar-w: 100px;
$deco-bar-h: 5px;

#service {
    .service-container {
        // background-image: url("../../assets/images/bg/service-bg.png");
        // background-position: center top $banner;
        // background-size: cover;
        background-color: $white;

        .banner {
            height: $banner;
            background-image: url("../../assets/images/photo/service-banner.png");
            background-size: cover;
        }

        .wrapper {
            margin: 0 auto;
            padding-top: 4rem;
            width: 1280px;
            min-height: calc(100vh - $banner);

            .content {
                white-space: pre-line;

                .deco-bar {
                    width: $deco-bar-w;
                    height: $deco-bar-h;
                    background-color: $blue;
                }

                .title {
                    font-size: 2rem;
                    font-weight: bolder;
                }
            }

            .imgs-container {
                display: grid;
                column-gap: 4rem;
                row-gap: 6rem;
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &.mobile {
        .service-container {
            .banner {
                background-position: center center;
            }

            .wrapper {
                margin: 0;
                padding: 4rem 2rem;
                width: 100%;

                > .flex {
                    flex-direction: column;
                }

                .content {
                    padding-left: 0;

                    .deco-bar {
                        display: none;
                    }

                    .title {
                        margin-top: 2rem;
                    }
                }

                .imgs-container {
                    column-gap: 2rem;
                    row-gap: 4rem;
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
}
