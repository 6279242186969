@import "../../based";

.section-2 {
    position: relative;

    .wrapper {
        position: relative;
        margin-left: $header * 2;
        height: 100%;
        z-index: $page;

        .half {
            position: relative;
            height: 50%;

            &.top {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;

                .ship {
                    position: absolute;
                    right: 30%;
                    bottom: -3%;
                    width: 40vw;
                    min-width: 400px;
                    height: 40vw;
                    min-height: 400px;
                    background-image: url("../../assets/images/bg/ship.png");
                    background-position: center bottom;
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                }
            }

            .logo-area {
                width: 430px;
            }

            .title {
                font-size: 4rem;
                font-weight: bolder;
                white-space: pre-line;
                line-height: 4rem;
                color: $white;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .desc {
                font-size: 2rem;
                white-space: pre-line;
                line-height: 2.2rem;
                color: $white;
                letter-spacing: 1px;
            }
        }
    }

    .wave-1 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: url("../../assets/images/bg/section-2-ocean-surface-effect.png");
        background-position: center bottom 50px;
        background-repeat: no-repeat;
        transform: scaleX(-1);
    }

    .wave-2 {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        background-image: url("../../assets/images/bg/section-2-ocean-surface-effect-2.png");
        background-position: 90px top;
        background-repeat: no-repeat;
        transform: scaleX(-1);
    }

    .section2-shadow {
        position: absolute;
        bottom: -3rem;
        left: -1.4rem;
        width: 36rem;
        height: 0.5rem;
        background-color: #000;
        border-radius: 50%;
        filter: blur(8px);
    }

    .reflection {
        position: absolute;
        bottom: -40rem / 626 * 396;
        left: -1.4rem;
        width: 36rem;
        height: 36rem / 626 * 396;
        background-image: url("../../assets/images/bg/section-2-ocean-surface-text-effect.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        opacity: 0.3;
        transform: scaleY(0.6);
        transform-origin: top center;
    }

    &.visible {
        .wrapper {
            .half {
                &.top {
                    .ship {
                        animation: ship-move 120s linear;
                    }
                }
            }
        }

        .wave-1 {
            animation: wave-down 5s linear infinite;
        }

        .wave-2 {
            animation: wave-right 3s linear infinite;
        }

        .reflection {
            animation: slow-up-2 15s ease-out;
        }

        .slow-down {
            animation: slow-down 15s ease-out;
        }
    }
}

@keyframes ship-move {
    0% {
        right: 0;
    }

    100% {
        right: 30%;
    }
}

@keyframes wave-down {
    0% {
        background-position: center bottom 50px;
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    85% {
        opacity: 1;
    }

    100% {
        background-position: center bottom -50px;
        opacity: 0;
    }
}

@keyframes wave-right {
    0% {
        background-position: 90px top;
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    85% {
        opacity: 1;
    }

    100% {
        background-position: 130px top;
        opacity: 0;
    }
}

@keyframes slow-down {
    0% {
        transform: translateY(-3rem);
    }

    10% {
        transform: translateY(0);
    }
}

@keyframes slow-up-1 {
    0% {
        transform: translateY(3rem);
    }

    10% {
        transform: translateY(0);
    }
}

@keyframes slow-up-2 {
    0% {
        transform: translateY(8rem) scaleY(0.3);
    }

    10% {
        transform: translateY(0) scaleY(0.6);
    }
}
