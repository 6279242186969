%section {
    height: 100vh;
    background-position: center center;
    background-size: auto 100%;
}

%button {
    cursor: pointer;
    user-select: none;
}

.clickable {
    @extend %button;
}

@mixin title($size: 4rem, $spacing: 2px, $color: #fff) {
    font-size: $size;
    font-weight: bolder;
    white-space: pre-line;
    color: $color;
    text-transform: uppercase;
    letter-spacing: 2px;
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// Extra large devices
@mixin xxl {
    @media (min-width: #{$screen-xxl-min + 1}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (min-width: ($screen + "px")) {
        @content;
    }
}
