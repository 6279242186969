@import "../../../based";

.side-btn {
    position: relative;
    padding-bottom: 100%;
    width: 100%;

    .side-icon {
        @extend %button;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.left {
            background-image: url("../../../assets/images/icon/left-icon.svg");
        }

        &.right {
            background-image: url("../../../assets/images/icon/right-icon.svg");
        }
    }
}
