@import "../../based";

.section-4 {
    height: 100%;

    .wrapper {
        display: flex;
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }

    .half {
        position: relative;
        width: 50%;
        height: 100%;

        &.left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            z-index: $page + 1;
        }

        .title {
            font-size: 3rem;
            font-weight: bolder;
            text-align: right;
            white-space: pre-line;
            line-height: 4rem;
            color: $white;
            letter-spacing: bolder;
            text-transform: uppercase;
        }

        .slide-show {
            margin-right: -20%;
            width: 120%;
        }

        .taiwan {
            position: relative;
            margin-top: 190px;
            margin-left: 190px;
            width: 376px * 0.8;
            height: 726px * 0.8;
            background-image: url("../../assets/images/bg/section-4-taiwan-effect.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: $page;
        }

        .port {
            position: absolute;
            z-index: $page + 2;

            &.taipei {
                top: 0;
                left: 65%;
            }

            // &.taoyuan {
            //     top: 23%;
            //     left: 52%;
            // }

            &.keelung {
                top: -8%;
                left: 70%;
            }

            &.taichung {
                top: 22%;
                left: 25%;
            }

            &.suao {
                top: 12%;
                left: 80%;
            }

            &.anping {
                top: 40%;
                left: 0;
            }

            &.kaoshiung {
                top: 60%;
                left: 5%;
            }
        }
    }
}
