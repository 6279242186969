@import "../../../based";

$border: 1px solid rgba($white, 0.5);
$border-active: 1px solid rgba($logo-blue, 1);
$light-border: 1px solid rgba($blue, 0.3);

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header;
    z-index: $header-z;

    > .header {
        position: relative;
        height: $header;
        border-bottom: $border;
        z-index: $header-z + 1;
        transition: 0.3s ease-out background-color, 0.5s ease-out border;

        .logo-container {
            @extend %button;

            width: 160px;
            opacity: 0;
            transition: 0.3s opacity;
            pointer-events: none;
        }
    }

    > .pillar {
        position: absolute;
        top: 0;
        width: $header;
        height: 100vh;
        z-index: $header-z + 2;
        transition: 0.3s ease-out height, 0.5s ease-out border;
        pointer-events: none;

        &.left {
            left: 0;
            border-right: $border;
        }

        &.right {
            right: 0;
            border-left: $border;
        }
    }

    &.active,
    &.mobile {
        > .header {
            background-color: rgba($dark-blue, 0.6);
            backdrop-filter: blur(5px);

            .logo-container {
                opacity: 1;
                pointer-events: all;
            }

            // border-bottom: $border-active;
        }

        > .pillar {
            &.left {
                height: $header;

                // border-right: $border-active;
            }

            &.right {
                height: $header;

                // border-left: $border-active;
            }
        }
    }

    > .header-menu {
        overflow: hidden;
        margin: 0 $header;
        width: 0;
        height: calc(100vh - $header);
        background-color: $dark-blue;
        opacity: 0;
        transition: 0.3s;

        &.active {
            width: calc(100% - #{2 * $header});
            opacity: 1;
        }
    }

    // &.dark {
    //     > .header {
    //         border-bottom: $border;
    //     }

    //     > .pillar {
    //         &.left {
    //             border-right: $border;
    //         }

    //         &.right {
    //             border-left: $border;
    //         }
    //     }

    //     &.active {
    //         > .header {
    //             background-color: rgba($white, 0.1);
    //         }
    //     }

    //     > .header-menu {
    //         background-color: $dark-blue;
    //     }
    // }

    &.light {
        > .header {
            background-color: rgba($white, 0.7);
            border-bottom: $light-border;
            backdrop-filter: blur(5px);

            .logo-container {
                opacity: 1;
            }
        }

        > .pillar {
            &.left {
                border-right: $light-border;
            }

            &.right {
                border-left: $light-border;
            }
        }

        > .header-menu {
            background-color: $white;
        }

        &.active {
            > .header {
                background-color: rgba($white, 0.7);
                border-bottom: $light-border;
                backdrop-filter: blur(5px);

                .logo-container {
                    opacity: 1;
                }
            }

            > .pillar {
                &.left {
                    border-right: $light-border;
                }

                &.right {
                    border-left: $light-border;
                }
            }

            > .header-menu {
                background-color: $white;
            }
        }
    }

    &.mobile {
        > .header {
            .logo-icon-mobile {
                width: 2rem;
                height: 2rem;
                background-image: url("../../../assets/images/icon/logo192.png");
                background-size: 100% 100%;
            }
        }

        > .header-menu {
            overflow: auto;
            margin: 0;
            width: 100%;
            height: 0;
            transition: 0.5s;

            &.active {
                height: calc(100vh - $header);
            }
        }
    }
}
