@import "../../based";

#tag {
    position: relative;

    .tag-container {
        overflow: hidden;
        padding: 0.3rem 1rem 0.3rem 2rem;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid $white;

        .yellow-bar {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 0.8rem;
            background-color: yellow;
        }

        .text {
            font-size: 1rem;
            white-space: nowrap;
            color: $white;
        }
    }

    .ping {
        position: absolute;
        top: calc(2rem + 4px);
        left: 2rem;
        width: 1px;
        height: 30px;
        background-color: $white;
    }

    .dot {
        position: absolute;
        top: calc(2rem + 34px);
        left: calc(2rem - 4px);
        width: 8px;
        height: 8px;
        background-color: #fff;
        border-radius: 5px;
    }
}

.visible {
    #tag {
        .tag-container {
            &.type-1 {
                animation: width-grow 1s linear;

                .ping {
                    animation: height-grow 1s linear;
                }
            }

            &.type-2 {
                animation: width-grow 1.5s linear;

                .ping {
                    animation: height-grow 1.5s linear;
                }
            }

            &.type-3 {
                animation: width-grow 2s linear;

                .ping {
                    animation: height-grow 2s linear;
                }
            }
        }
    }
}

@keyframes height-grow {
    0% {
        height: 0;
    }

    100% {
        height: 30px;
    }
}
