@import "../../../based";

.section-m-5 {
    height: auto;

    .wrapper {
        margin: 0 2rem;

        > .title {
            text-align: center;

            .bar {
                display: inline-block;
                width: 4rem;
                height: 8px;
                background-color: $blue;
            }

            .text {
                display: inline-block;
                font-size: 2.2rem;
                font-weight: bolder;
                color: $white;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }

        .service {
            .img-block {
                .img-container {
                    width: 100%;
                    background-position: bottom center;
                    background-repeat: no-repeat;
                    background-size: 100% auto;

                    &.idx-1 {
                        padding-bottom: 50%;
                        background-image: url("../../../assets/images/photo/service-block-1.png");
                    }

                    &.idx-2 {
                        padding-bottom: 50%;
                        background-image: url("../../../assets/images/photo/service-block-2.png");
                    }
                }
            }

            > .content {
                text-transform: uppercase;

                > .title {
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 2rem;
                        height: 2rem;
                        background-position: center center;
                        background-size: 100% 100%;

                        &.cog-repair {
                            background-image: url("../../../assets/images/icon/cog-repair.svg");
                        }

                        &.ship-shield {
                            background-image: url("../../../assets/images/icon/ship-shield.svg");
                        }
                    }

                    .text {
                        margin-left: 2rem;
                        font-size: 1.4rem;
                        font-weight: bold;
                        color: $white;
                        letter-spacing: 1px;
                    }
                }

                > .service-list {
                    .service-item {
                        @extend %button;

                        line-height: 2.2rem;
                        color: $white;
                        letter-spacing: 1px;
                    }
                }
            }
        }

        .divider-container {
            margin: 0 auto;
            width: 100%;
        }
    }
}
