@import "../../based";

.section-3 {
    position: relative;
    height: 100%;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 1024px;
        height: 100%;

        .image-container {
            width: 300px;

            .about-us-photo {
                padding-bottom: calc(100% / 385 * 500);
                width: 100%;
                background-image: url("../../assets/images/photo/about-us-photo.jpg");
                background-position: center 0;
                background-size: auto 100%;
                box-shadow: 30px 100px 100px rgba(0, 0, 0, 0.6);
            }
        }

        .title {
            font-size: 3rem;
            font-weight: bolder;
            color: $white;
            text-transform: uppercase;
            text-shadow: 15px 30px 14px rgba(0, 0, 0, 0.3);
            letter-spacing: 2px;
        }

        .subtitle {
            font-size: 1.6rem;
            font-weight: bolder;
            color: $white;
            text-shadow: 15px 30px 14px rgba(0, 0, 0, 0.3);
            letter-spacing: 2px;
        }

        .content {
            font-size: 1.2rem;
            white-space: pre-line;
            color: $white;
            text-shadow: 15px 30px 14px rgba(0, 0, 0, 0.3);
        }
    }

    .bright {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/images/bg/section-3-under-water-effect.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        transform-origin: top center;
        opacity: 0;
        pointer-events: none;

        &.idx-2 {
            transform: scaleX(-1);
        }

        &.idx-3 {
            top: 5%;
            background-size: 60% auto;
        }
    }

    &.visible {
        .bright {
            &.idx-1 {
                animation: shine-scale 30s linear infinite;
            }

            &.idx-2 {
                animation: shine 7s linear 2s infinite;
            }

            &.idx-3 {
                animation: shine 3s linear 2s infinite;
            }
        }
    }
}

@keyframes shine {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes shine-scale {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale(1);
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(0.6);
    }
}
